.container {
  display: flex;
  flex-direction: column;
}

.background {
  background-repeat: no-repeat;
  background-size: 6500px;
  background-position: center bottom;
}

.MainHome {
  background-image: url("../../images/backgrounds/Background1.svg");
  background-color: #160828;
}

.ProjectsPage {
  background-image: url("../../images/backgrounds/Background3.svg");
  background-color: #160828;
}

.AboutPage {
  background-image: url("../../images/backgrounds/Background2.svg");
  height: fit-content;
  background-color: #492b7c;
}

.ExperiencePage {
  background-image: url("../../images/backgrounds/Background4.svg");
  background-color: #492b7c;
  height: fit-content;
  overflow: visible;
  flex-grow: 1;
}

.titleProject {
  text-align: center;
  font-family: "Roboto Mono", monospace;
  font-weight: 1000;
  font-size: 50px;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 768px) {
  .MainHome {
    padding-bottom: 100px;
  }
  .ExperiencePage {
    padding-bottom: 300px;
  }
  .ProjectsPage {
    padding-bottom: 100px;
  }
  .AboutPage {
    padding-bottom: 300px;
  }
  .titleProject {
    font-size: 40px;
  }
}

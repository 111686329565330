.Projects {
  color: #fffaed;
  padding-top: 0px;
  margin: auto;
  max-width: 1350px;
  width: 90%;
  margin-top: 2vh;
  padding-bottom: 30vh;
}

.titleProject {
  text-align: center;
  padding-bottom: 50px;
  font-family: "Roboto Mono", monospace;
  font-weight: 1000;
}

.noBottom {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.ButtonWrapper {
  float: right;
  margin-top: 10px;
}

.projectLogo {
  max-width: 240px;
  max-height: 240px;
  margin-right: 20px;
  border-radius: 8px;
  margin-top: 10px;
  background-color: #161519;
  padding: 20px;
  border: 2px solid #fffaed;
}

.projectTitle {
  max-width: 1080px;
  width: 90vw;
}

.projectText {
  max-width: 1000px;
  margin-left: 20px;
  margin-right: 20px;

}

.projectDescriptionHolder{
  justify-content: center;
}

.TagProjectHolder {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: -6px;
}

.quickLink :hover{
  cursor: pointer;
}

.linkButton {
  width: 284px;
}

.logoHolder{
  width: 300px;
}

.reverser{
  display: flex;
  flex-direction: column;
}


@media (max-width: 1350px) {

  .linkButtonMobile {
    width: calc(90vw - 40px);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
  }
}

@media (max-width: 762px) {
  .reverser{
    flex-direction: column-reverse;
  }

  .TagProjectHolder {
    margin-top: 25px;
    margin-bottom: 0px;
  }
}
@font-face {
  font-family: 'Roboto Mono';
  font-stretch: 100%; /* Note that font-stretch is a % of normal width */
  font-style: normal; /* See note below */
  font-weight: 100 1000; /* Note that font-weight is a number */
  src: url("./fonts/RobotoMono-VariableFont_wght.ttf") format('woff2');
}

body {
  margin: 0;
  font-family: "Roboto Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.About {
  color: #fffaed;
  width: fit-content;
  margin: auto;
  padding-bottom: 30vh;
}

.Title {
  font-family: "Roboto Mono", monospace;
  font-size: 65px;
}

.Text {
  max-width: 80vh;
  margin-right: 40px;
  margin-left: 40px;
}

.textWrapper {
  padding-left: 20px;
  padding-right: 20px;
}

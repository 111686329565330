.Tag {
  color: #160828;
  font-family: "Roboto Mono", monospace;
  margin-right: 7.5px;
  margin-bottom: 7.5px;
  font-size: 15px;
  border-radius: 5px;
  width: fit-content;
  padding: 3px 8px;
  font-weight: 400;
}

.MainIntro {
  color: #fffaed;
  width: fit-content;
  padding-top: 30vh;
  padding-bottom: 30vh;
  display: flex;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.WordsWrapper {
  width: 76.5vw;
}

.Name {
  font-family: "Roboto Mono", monospace;
  font-size: 8.6vw;
  font-weight: 1000;
}

.Break {
  color: #fffaed;
  margin-bottom: 10px;
}

.TagHolder {
  display: flex;
  flex-wrap: wrap;
}


.DescriptionText {
  font-family: "Roboto Mono", monospace;
  display: flex;
}

.FirstLine {
  margin-right: 10px;
}

.pPickWrapper {
  width: 250px;
}

.pPic {
  margin-top: 10px;
  margin-left: 50px;
  width: 240px;
  height: 240px;
  border-radius: 25px;
  border: 3px solid #fffaed;
}

.IconWrapper {
  margin-left: 50px;
}

.Icon {
  width: 48px;
  height: 48px;
}

.breakline{
  display: none;
}

.LinkWrapper{
  display: none;

}

@media screen and (max-width: 400px) {
  .Name {
    font-size: 35px;
  }
  .DescriptionText {
    font-size: 11px;
  }
  .WordsWrapper {
    width: 300px;
  }
}

@media screen and (max-width: 960px) {
  .pPickWrapper {
    display: none;
  }
  .LinkWrapper{
    display: inline;
  }
  .breakline{
    display: inline;
  }
  .MainIntro {
    padding-top: 10vh;
  }
}

@media screen and (min-width: 750px) {
  .Name {
    font-size: 65px;
  }
  .WordsWrapper {
    width: 600px;
  }
  .DescriptionText {
    font-size: 20px;
  }
}

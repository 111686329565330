.Button {
  font-family: "Roboto Mono", monospace;
  margin-right: 10px;
  margin-top: 5px;

  margin-bottom: 5px;
  font-size: 25px;
  border-radius: 5px;
  border: 2px solid;
  color: "#160828";
  padding: 5px 15px;
  font-weight: 400;
  transition: background 0.25s, color 0.25s, border 0.25s;
  cursor: pointer;
}

.Button:hover {
  background: #160828 !important ;
  color: #fffaed !important ;
  border: 2px solid #fffaed !important ;
}
